import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/Home";
import Menu from "./containers/Menu";
import Saldo from "./containers/Saldo";
import NotFound from "./containers/NotFound";
// import { isAuthenticated } from "./services/auth";

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={props =>
//       isAuthenticated() ? (
//         <Component {...props} />
//       ) : (
//         <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
//       )
//     }
//   />
// );
const MyRoutes = () => (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/cardapio" element={<Menu />} />
      <Route exact path="/saldo" element={<Saldo />} />

      <Route path="*" component={() => <NotFound />} />
    </Routes>
);

export default MyRoutes;
