import axios from "axios";

const api = axios.create({
        baseURL: process.env.REACT_APP_KITNET_BASE_URL
});

api.interceptors.request.use(async config => {
    config.withCredentials = true;
    return config;
});


export default api;
