import React from 'react'
import './Home.css'
export default function Home() {
  return (
    <div className="home">
      <h1 className="my-3">A melhor casinha</h1>
      <hr />
      <p className="my-3">
        <a href="/saldo" class="btn btn-lg btn-primary">Saldo</a>
      </p>
      <hr />
      <p className="my-3">
        <a href="/cardapio" class="btn btn-lg btn-warning">Cardápio</a>
      </p>
    </div>
  )
}
