import React from 'react'

export default function CarregaMenu({categorias, cardapio}) {
  function toggle(e){
    e.preventDefault()
    const targetId = e.target.id
    const items = document.getElementById(`${targetId}-items`)
    items.style.display === "none" ? items.style.display = 'inline' : items.style.display = 'none'
      
  }
  
  return (
    <div>
        { categorias.map((i) => {
        if(String(i) !== "undefined"){
          return (

            <div className='my-2'   key={(String(i).length)}>
              <div className="categoryTitle my-2">
                <span id={(String(i).length)} onClick={toggle}  className="categoryTitleText"> {String(i).replace(/_/g, " ")}</span>
              </div>
              <div id={(String(i).length) + "-items"}  key={(String(i).length)+1} className="my-2 container" style={{display: "none"}}>
              { cardapio.map((e) => {
                if(e.category === i) {
                  return (
                    <div key={e.id} className="listaDeProdutos"  >
                      <div className="row">
                        <div className="col-8">
                        {e.title}
                        </div>
                        <div className="col-4">
                        R$: {e.price},00
                        </div>
                      </div>
                   </div>
                  )
                } else {
                  return ( <></>)
                }
                
              })}
              
              </div>
              <hr />
            </div>
          )
        } return (<></>)
      })}
    </div>
  )
}
