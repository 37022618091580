import React from 'react'
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';



import MyRoutes from "./Routes";


function App() {
  function home(){
    window.location = '/'
  }
  return (
    <div className="App">
       <img onClick={home} className="img-responsive" width="50%" src='logo512.png' alt='Logo KitnetBar'/>
      <MyRoutes />
      <footer className="footer">
        <div className="text-center my-2" > RagazziD
        © { new Date().getFullYear() }
          </div>
          <a target="_blank" rel="noreferrer" className="" href="https://ragazzid.com.br/">RagazziD</a>
      </footer>
     
    </div>
  );
}

export default App;
