import React from 'react'

export default function User({credit, comanda}) {
  return (
    <div>
        { comanda ? (
            <>
                <div className="container my-4">
                    <h2>Comanda {comanda}</h2>
                    <hr />
                    <h4>Saldo</h4>
                    <h1>R$: {parseInt(credit).toFixed(2)}</h1>
                </div>
                {/* <h3>Consumo</h3>
                <div>
                    { user.credit_usage.map((p) => (
                        <div key={p.usage_id} className="row row-main" style={{textDecoration: p.valid ?  "": "line-through" }}>
                        <div className="col-6">
                            <div className="row d-flex">
                                <p><b>{p.title}</b></p>
                            </div>
                            <div className="row d-flex">
                            <p className="text-muted">Vendido por {p.admin_fullname.split(" ")[0]}</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row d-flex justify-content-end">
                                <p><b>R$ {(p.itemTotal)}</b></p>
                            </div>
                            <div className="row d-flex  justify-content-end">
                            <p className="text">{new Date(p.created_at).toLocaleString("pt-BR")}</p>
                            </div>
                            
                        </div>                    
                        <div className=" my-2 text-center">------</div>
                    </div>
                    ))}
                </div>
                <hr />
                <h3>Recargas</h3>
                <div>
                    { user.credit_charge.map((p) => (
                        <div key={p.usage_id} className="row row-main">
                        <div className="col-12">
                            <div className="row d-flex justify-content-end">
                                <p><b>R$ {(p.value)}</b></p>
                            <p className="text">{new Date(p.created_at).toLocaleString("pt-BR")}</p>
                            </div>
                            
                        </div>                    
                        <div className=" my-2 text-center">------</div>
                    </div>
                    ))}
                </div> */}
            </>
        ): (
            <></>
        )}

    </div>

  )
}
